
.load-bar-slider {
    position: relative;
    width: 100%;
    flex: 100%;
    height: 0;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity 100ms ease-in;


    &.active {
        opacity: 1;
        pointer-events: auto;
        height: 3px;
    }

    &--fixed {
        position: fixed;
        top: 0;
        inset-inline-start: 0;
        z-index: 9999;
    }
}

.load-bar-slider .load-bar-line {
    position: absolute;
    background:  v-bind(lineColor);
    width: 100%;
    height: 100%;
}

.load-bar-slider .load-bar-break {
    position: absolute;
    background: v-bind(backgroundColor);
    width: 50px;
    height: 3px;
}

.load-bar-slider .load-bar-break.load-bar-1 {
    -webkit-animation: bar-loading 2s infinite;
    -moz-animation: bar-loading 2s infinite;
    -o-animation: bar-loading 2s infinite;
    animation: bar-loading 2s infinite;
}

.load-bar-slider .load-bar-break.load-bar-2 {
    -webkit-animation: bar-loading 2s .5s infinite;
    -moz-animation: bar-loading 2s .5s infinite;
    -o-animation: bar-loading 2s .5s infinite;
    animation: bar-loading 2s .5s infinite;
}

.load-bar-slider .load-bar-break.load-bar-3 {
    -webkit-animation: bar-loading 4s 1s infinite;
    -moz-animation: bar-loading 4s 1s infinite;
    -o-animation: bar-loading 4s 1s infinite;
    animation: bar-loading 4s 1s infinite;
}

@keyframes bar-loading{from{inset-inline-start:0}to{inset-inline-start:100%}}


